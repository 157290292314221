.preview {
  width: 100%;
}

.preview__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 16px;
  
  @media (max-width: $mobile-width) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 24px;
  }
}

.preview__title {
  max-width: 280px;
  margin: 0;
  padding: 0;

  font-size: 48px;
  font-weight: bold;
  line-height: 58px;

  @media (max-width: $mobile-width) {
    padding-bottom: 24px;
  }
}

.preview__subtitle {
  max-width: 280px;
  margin: 0;
  padding: 0;

  font-size: 22px;
}

.preview__cover {
  width: 100%;

 & img {
   width: 100%;
   height: auto;
 }
}

.preview__lang-selection {
  @media (max-width: $mobile-width) {
    display: none;
  }
}

.preview__contacts {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 40px 0;

  list-style: none;
}

.preview__contact_item {
  margin-left: 8px;

  &:first-child {
    margin-left: 0;
  }
}

.preview__contact_link {

}

.preview__contact_img {
  width: 32px;
}
