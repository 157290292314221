.portfolio {
  padding: 0;
  margin: 0;
}

.portfolio__title {
  padding: 0;
  margin: 0 0 47px;

  font-size: 34px;
  font-weight: bold;
  text-align: center;

  &::before {
    content: " ";
    display: block;
    padding-top: 152px;
    margin-top: -152px;
  }

  @media (max-width: $mobile-width) {
    margin-bottom: 32px;

    &::before {
      padding-top: 112px;

    }
  }
}

.portfolio__list {
  padding: 0;
  margin: 0;

  list-style: none;
}

.portfolio__item {
  padding: 0;
  margin: 0 0 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $mobile-width) {
    margin-bottom: 77px;
  }
}

.portfolio__info {
  display: flex;
  height: auto;
  margin: 0 auto;
  padding: 0;

  @media (max-width: $tablet-width) {
    flex-direction: column;
  }
}

.portfolio__cover {
  width: 64%;
  text-align: center;

  @media (max-width: $tablet-width) {
    width: 100%;
  }

  @media (max-width: $mobile-width) {
    margin-bottom: 33px;
  }
}

.portfolio__description {
  width: 38%;
  padding-left: 24px;

  font-size: 20px;

  & p {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: $tablet-width) {
    width: 100%;
    padding-left: 0;
  }
}

.portfolio__img {
  width: 100%;
}

.portfolio__name-project {
  padding: 0;
  margin: 0 0 20px 0;

  font-size: 28px;
  text-align: left;
}

.portfolio__stack_list {
  display: flex;
  padding: 0;
  margin: 24px 0 0 0;

  list-style: none;
}

.portfolio__stack_item {
  margin-left: 8px;
  line-height: 0;

  &:first-child {
    margin-left: 0;
  }
}

.portfolio__stack_logo {
  width: 40px;
}

.portfolio__links {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  & > * {
    margin-top: 24px;
  }

  & > *:first-child {
    margin-top: 0;
  }

  @media (max-width: $tablet-width) {
    flex-direction: row;
    align-items: center;

    & > * {
      margin-top: 0;
      margin-left: 24px;
    }

    & > *:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: $mobile-width) {
    flex-direction: column;

    & > * {
      margin-top: 24px;
      margin-left: 0;
    }

    & > *:first-child {
      margin-top: 0;
    }
  }
}

.portfolio__btn_demo {
  max-width: 200px;

  @media (max-width: $mobile-width) {
    max-width: none;
    width: 100%;
  }
}

.portfolio__repository {
  max-width: 200px;

  @media (max-width: $mobile-width) {
    max-width: none;
    width: 100%;
  }
}
