.button {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  min-height: 48px;
  padding: 10px 30px;

  font-size: 18px;
  font-weight: bold;
  color: $while;
  text-align: center;

  background-color: $black;
  text-decoration: none;

  transition: all .2s;

  &:hover {
    color: $black;
    background-color: $gray;
  }
}

.button--secondary {
  color: $black;
  text-decoration: none;
  background-color: transparent;
  border: 2px solid $black;

  transition: all .2s;

  &:hover {
    color: $gray;
    text-decoration: none;
    background-color: transparent;
    border: 2px solid $gray;
  }
}


