.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contacts__title {
  margin: 0 0 30px;
  padding: 0;

  &::before {
    content: " ";
    display: block;
    padding-top: 112px;
    margin-top: -152px;
  }
}

.contacts__subtitle {
  margin: 0 0 30px;
  padding: 0;

  text-align: center;

  @media (max-width: $mobile-width) {
    margin-bottom: 40px;
  }
}

.contacts__send-message {
  width: 240px;
  margin: 0 0 86px;
  padding: 0;

  @media (max-width: $mobile-width) {
    width: 100%;
    margin-bottom: 60px;
  }
}
