// Цвета
$black: #000000;
$black-2: #070707;
$light-gray: #F6F6F6;
$gray: #828282;
$while: #FFFFFF;

// Размеры экранов
$desktop-width: 1200px;
$tablet-width: 768px;
$mobile-width: 426px;
