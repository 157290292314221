.skills {
  width: 100%;
  padding: 0;
}

.skills__title {
  margin: 0 0 68px;

  font-size: 34px;
  text-align: center;

  @media (max-width: $mobile-width) {
    margin-bottom: 50px;
  }
}

.skills__subtitle {
  margin: 0 0 84px;

  text-align: center;

  @media (max-width: $mobile-width) {
    margin-bottom: 70px;
  }
}

.skills__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -16px 0 0;
  padding: 0;

  list-style: none;
}

.skills__item {
  width: 168px;
  height: 206px;
  margin-right: 16px;
  margin-bottom: 80px;

  @media (max-width: $mobile-width) {
    width: 100%;
    margin-right: 0;
  }
}
