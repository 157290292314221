.main-nav {
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;

  border-bottom: 1px solid $gray;

  @media (max-width: $mobile-width) {
    position: relative;
    padding: 0;
    border-bottom: none;

    background-color: $while;
  }
}

.main-nav__wrapper {
  @media (max-width: $mobile-width) {
    display: none;
    position: absolute;
    justify-content: space-between;

    width: calc(100% + 42px);
    height: calc(100vh - 24px);
    margin-left: -30px;

    background-color: $while;
  }
}

.main-nav--opened .main-nav__wrapper {
  display: block;
}

.main-nav__toggle {
  display: none;

  &::before {
    content: "";

    position: absolute;
    top: 7px;
    right: 0;

    width: 34px;
    height: 4px;

    background-color: $black-2;
    box-shadow: 0 8px 0 0 $black-2,
                0 16px 0 0 $black-2;
  }

  @media (max-width: $mobile-width) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    width: 34px;
    height: 34px;
    padding: 0;

    background-color: $while;

    cursor: pointer;
    border: none;
  }
}

.main-nav--opened .main-nav__toggle {
  top: 0;
  right: 0;
  left: auto;

  &::before,
  &::after {
    content: "";

    position: absolute;
    top: 15px;
    right: 0;

    width: 34px;
    height: 4px;

    background-color: $black-2;
    box-shadow: none;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.main-nav__list {
  display: flex;
  justify-content: space-between;

  margin: 0;
  padding: 0;

  list-style: none;
  
  @media (max-width: $mobile-width) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding-left: 26px;
  }
}

.main-nav__item {
  font-weight: bold;
  margin-right: 16px;

  text-align: center;

  & a {
    color: $gray;
    text-decoration: none;

    transition: 0.2s color;
  }

  & a:hover {
    color: $black;
  }

  @media (max-width: $mobile-width) {
    font-size: 34px;
    padding-bottom: 60px;
  }
}

.main-nav__lang-selection {
  display: none;

  @media (max-width: $mobile-width) {
    display: flex;
    justify-content: right;
    align-items: flex-end;

    margin-bottom: 57px;
  }
}
