.lang-selection {
  font-size: 16px;
  text-align: left;

  transform: rotate(180deg);
  writing-mode: vertical-rl;

  &--menu {
    font-size: 34px;

    transform: rotate(0deg);
  }
}

.lang-selection__lang {
  font-weight: bold;
  color: $gray;
  text-decoration: none;

  &--active {
    color: $black;
  }
}
