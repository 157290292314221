html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;

  font-family: "Gilroy", "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: $black;
}

body * {
  box-sizing: border-box;
}

.overflow-hidden {
  overflow: hidden;
}

.visually-hidden:not(:focus):not(:active) {
  position: relative;

  width: 0;
  height: 0;
  margin: 0;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
