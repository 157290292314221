.about-me {
  padding: 0;
}

.about-me__title {
  margin: 0 0 18px;

  font-size: 34px;
  text-align: left;

  @media (max-width: $mobile-width) {
    margin: 0 0 30px;
  }
}

.about-me__text {
  margin: 0 0 40px;

  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
}
