.page__wrapper {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.page__container {
  width: 100%;

  &--bg-gray {
    background-color: $light-gray;
  }
}

.page__container-wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

.page__header {
  position: fixed;
  width: 100%;
  height: 88px;

  background-color: $while;

  @media (max-width: $mobile-width) {
    height: 66px;
    padding-top: 16px;
    padding-bottom: 16px;

    border-bottom: 1px solid $gray;
  }
}

.page__main {}

.page__preview {
  padding-top: 112px;
  padding-bottom: 64px;

  @media (max-width: $mobile-width) {
    padding-top: 80px;
    padding-bottom: 83px;
  }
}

.page__about-me {
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  padding-bottom: 64px;

  @media (max-width: $mobile-width) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.page__skills {
  padding-top: 64px;
  padding-bottom: 64px;

  @media (max-width: $mobile-width) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.page__portfolio {
  padding-top: 64px;
  padding-bottom: 64px;

  @media (max-width: $mobile-width) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.page__contacts {
  padding-top: 64px;
  padding-bottom: 64px;

  @media (max-width: $mobile-width) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

