@font-face {
  font-weight: normal;
  font-family: "Gilroy";
  font-style: normal;
  src:
    url("../fonts/Gilroy-Medium.ttf") format('opentype'),
    url("../fonts/Gilroy-Medium.woff2") format("woff2"),
    url("../fonts/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-weight: bold;
  font-family: "Gilroy";
  font-style: normal;
  src:
    url("../fonts/Gilroy-Bold.ttf") format('opentype'),
    url("../fonts/Gilroy-Bold.woff2") format("woff2"),
    url("../fonts/Gilroy-Bold.woff") format("woff");
}
